var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photoStart"},[(_vm.preparing == true)?_c('LoadingPlaceholder'):_vm._e(),(
      _vm.photos &&
        ((_vm.photos.spotOne && _vm.photos.spotOne.length > 0) ||
        (_vm.photos.spotTwo && _vm.photos.spotTwo.length > 0))
    )?[_c('div',{staticClass:"nav nav-tabs",attrs:{"id":"nav-tab","role":"tablist"}},[(_vm.photos.spotOne && _vm.photos.spotOne.length > 0)?_c('a',{staticClass:"nav-item nav-link active",attrs:{"id":"spoTOne-tab","data-toggle":"tab","href":"#spoTOne","role":"tab","aria-controls":"spoTOne","aria-selected":"true"}},[_vm._v("Spot: "+_vm._s(_vm.photos.spotOne[0].spotId))]):_vm._e(),(_vm.photos.spotTwo && _vm.photos.spotTwo.length > 0)?_c('a',{staticClass:"nav-item nav-link",attrs:{"id":"spotTwo-tab","data-toggle":"tab","href":"#spotTwo","role":"tab","aria-controls":"spotTwo","aria-selected":"false"}},[_vm._v("Spot: "+_vm._s(_vm.photos.spotTwo[0].spotId))]):_vm._e()]),_c('div',{staticClass:"tab-content",attrs:{"id":"nav-tabContent"}},[(_vm.photos.spotOne && _vm.photos.spotOne.length > 0)?_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"spoTOne","role":"tabpanel","aria-labelledby":"spoTOne-tab"}},[_c('SessionOverview',{attrs:{"runs":_vm.photos.spotOne,"display-properties":['createDate', 'card'],"loading":_vm.loading,"filter":_vm.filter,"installation-type":_vm.installationType,"installation-id":_vm.installationId},on:{"loadSessions":_vm.loadSessions}})],1):_vm._e(),(_vm.photos.spotTwo && _vm.photos.spotTwo.length > 0)?_c('div',{staticClass:"tab-pane fade",attrs:{"id":"spotTwo","role":"tabpanel","aria-labelledby":"spotTwo-tab"}},[_c('SessionOverview',{attrs:{"runs":_vm.photos.spotTwo,"display-properties":['createDate', 'card'],"loading":_vm.loading,"filter":_vm.filter,"installation-type":_vm.installationType,"installation-id":_vm.installationId},on:{"loadSessions":_vm.loadSessions}})],1):_vm._e()])]:(
      _vm.preparing == false &&
        (_vm.photos == null ||
        (_vm.photos.spotOne.length == 0 && _vm.photos.spotTwo.length == 0))
    )?_c('p',[_vm._v(" No data available. ")]):_vm._e(),_c('SweetModal',{ref:"photoStartmodal",attrs:{"id":_vm.modalId,"title":"Session","width":"75%"},on:{"closeModal":_vm.closeModal}},[_c('template',{slot:"box-action"},[_c('toggle-button',{staticClass:"mr-3 mt-2",attrs:{"labels":{ checked: 'decorated', unchecked: 'normal' },"width":100,"height":30,"font-size":12},model:{value:(_vm.viewDecoratedPhotos),callback:function ($$v) {_vm.viewDecoratedPhotos=$$v},expression:"viewDecoratedPhotos"}})],1),_c('sweet-modal-tab',{attrs:{"id":"tab1","title":"Photo"}},[(_vm.altImageAvailable)?_c('img',{staticClass:"img-fluid",attrs:{"id":"img","src":_vm.altImageUrl}}):_c('ImagePlaceholderSvg',{staticClass:"img-fluid"})],1),_c('sweet-modal-tab',{attrs:{"id":"tab2","title":"Details"}},[(_vm.payload != null)?_c('table',{staticClass:"defaultTable"},[_c('colgroup',[_c('col',{attrs:{"width":"200"}}),_c('col')]),_c('tbody',[_c('tr',[_c('th',[_vm._v("Id")]),_c('td',{staticClass:"lineBreaks"},[_vm._v(" "+_vm._s(_vm.payload.id)+" ")])]),_c('tr',[_c('th',[_vm._v("Card Number")]),_c('td',{staticClass:"lineBreaks"},[_vm._v(" "+_vm._s(_vm.payload.identificationId)+" ")])]),_c('tr',[_c('th',[_vm._v("Create date")]),_c('td',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.dateTime_fromNow(_vm.payload.created)),expression:"dateTime_fromNow(payload.created)"}]},[_vm._v(" "+_vm._s(_vm.dateTime_dateTime(_vm.payload.created))+" ")])]),_c('tr',[_c('th',[_vm._v("Image Url")]),_c('td',[_vm._v(_vm._s(_vm.payload.imageUrl))])]),_c('tr',[_c('th',[_vm._v("Snapshot Url")]),_c('td',[_vm._v(_vm._s(_vm.payload.snapshotUrl))])]),_c('tr',[_c('th',[_vm._v("Spot Id")]),_c('td',[_vm._v(_vm._s(_vm.payload.spotId))])])])]):_vm._e()]),_c('sweet-modal-tab',{attrs:{"id":"tab3","title":"EXIF"}},[(_vm.exifData)?_c('pre',[_vm._v(_vm._s(_vm.exifData))]):[_vm._v(" "+_vm._s(_vm.$t("noDataAvailable"))+" ")]],2)],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }